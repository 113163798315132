import {
  EnrichmentPlugin,
  BrowserConfig,
  Event
} from '@amplitude/analytics-types'

export default class AmplitudePlugin implements EnrichmentPlugin {
  name = 'marketing-event-plugin'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type = 'enrichment' as any

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async setup (_config: BrowserConfig): Promise<void> {
    return undefined
  }

  async execute (event: Event): Promise<Event | null> {
    if (event.event_type === '$identify') return event
    if (event.event_type.startsWith('session_')) return event

    // Make event names project specific to follow common conventions
    let eType: string = event.event_type.replace('[Amplitude] ', '')
    eType = eType.replace(' ', '_')
    // NOTE: If we make use of useAmplitudeContext where we call .track, we won't need to dedup the prefix here
    if (!eType.startsWith('MKTG_Site_')) {
      event.event_type = 'MKTG_Site_' + eType
    }

    event.event_properties = {
      ...event.event_properties,
      service: 'marketing_website'
    }

    return event
  }
}
