const PARAMETERS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'gclid', 'fbclid', 'msclid', 'ttclid', 'referral_url', 'lead_source', 'landing_page_url']
const MAX_AGE = 1209600/* two weeks */
const LEAD_SOURCE = 'Website'

export const setCookie = (name: string, value: string, path: string = '/', domain: string = '.rula.com'): void => {
  document.cookie = `${name}=${value}; Domain=${domain}; Path=${path}; Max-Age=${MAX_AGE}`
}

export const utmsCookieDecoded = (): URLSearchParams => {
  const encoded = document?.cookie?.split('; ').find((c) => c.startsWith('UTM='))?.split('=')[1]
  if (encoded !== undefined) {
    const decoded = window.atob(encoded)
    return new URLSearchParams(decoded)
  } else {
    return new URLSearchParams()
  }
}

export const setUtmsCookie = (values: URLSearchParams, path?: string, domain?: string): void => {
  const utms = utmsCookieDecoded()
  values.forEach((value, key) => {
    if (PARAMETERS.includes(key)) {
      utms.set(key, value)
    }
  })
  const cookieValue = window.btoa(utms.toString())

  if (cookieValue.length > 0) {
    setCookie('UTM', cookieValue, path, domain)
  }
}

/**
 * Sticky UTM and Ad parameters.
 */
export const stickUtmParameters = (): void => {
  const determineReferrer = (url: string): string | undefined => {
    if (url.length < 1) return

    return url
  }

  const query = new URLSearchParams(window.location.search)
  const utmParams = new URLSearchParams()

  for (const name of PARAMETERS) {
    const value = query.get(name)

    if (value !== null) {
      utmParams.set(name, value)
    }
  }

  const referrer = determineReferrer(document.referrer)
  if (referrer != null) {
    utmParams.set('referral_url', referrer)
  }

  utmParams.set('lead_source', LEAD_SOURCE)

  const utms = utmsCookieDecoded()
  if (utms.get('landing_page_url') === null) {
    // Salesforce field has a 255 char limit
    utmParams.set('landing_page_url', window.location.pathname?.substring(0, 255))
  }

  setUtmsCookie(utmParams)
}
