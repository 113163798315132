import { AmplitudeBrowserClient, AmplitudeBrowserClientOptions, DEFAULT_AMPLITUDE_OPTIONS, ProductArea, ProductCode, Trigger } from '@pathccm/amplitude-browser'
import AmplitudePlugin from './amplitude-plugin'

export const MARKETING_AMPLITUDE_OPTIONS: AmplitudeBrowserClientOptions = {
  ...DEFAULT_AMPLITUDE_OPTIONS,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  apiKey: process.env.GATSBY_AMPLITUDE_API_KEY!,
  productCode: ProductCode.MARKETING_SITE,
  productArea: ProductArea.MARKETING_SITE,
  plugins: [new AmplitudePlugin()]
}

export const amplitudeAnalytics = (): void => {
  const client = new AmplitudeBrowserClient(MARKETING_AMPLITUDE_OPTIONS)

  // Set a timeout to make sure this event occurs after the PageLoad event
  // This is a temporary stop-gap until the Amplitude race condition issue seen in DOG-1756 are resolved
  setTimeout(() => {
    client.track(Trigger.UTM_PAGE_LOAD, { delayed_utm: true })
  }, 2000)

  // Capture Link Clicks
  window.addEventListener('click', (event: Event) => {
    let t

    if (event.target instanceof HTMLAnchorElement) {
      t = event.target
    } else if ((event.target as HTMLElement)?.parentElement instanceof HTMLAnchorElement) {
      if (event.target === null) return

      // Some instances we wrap buttons in a tags
      t = (event.target as HTMLElement).parentElement
    } else {
      return
    }

    const payload = {
      href: t.href,
      text: t.text,
      id: t.id,
      hash: t.hash,
      hostname: t.hostname,
      className: t.className,
      trigger: 'Interaction'
    }

    if (t.hostname !== window.location.hostname) {
      client.track(Trigger.OUTBOUND_LINK_CLICKED, payload)
    } else if (t.hash !== undefined && t.hash !== '') {
      client.track(Trigger.ANCHOR_LINK_CLICKED, payload)
    }
  })
}
